import { ReplaySubject } from "rxjs";
import { AppConfig } from "../utils/app-config";
import { WidgetNetworkService } from "../NetworkServices/widgetNetworkService";
import { IFrameWidgetCommService } from "./IframeWidgetAppService";
import { WidgetStylingUtils } from "./WidgetStylingUtils";
import { BREAKPOINTS_DEVICE_SIZE } from "./utils/contants";
import { CommMessage, IEvents, OEvents } from "./utils/models";
export class GetAChatBotFrame {
    constructor(props) {
        Object.defineProperty(this, "scriptData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iframe_element", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iframe_comm_service", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iframe_ready_notifier", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "widget_config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "location", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "widgetUtils", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deviceSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.scriptData = props;
        this.iframe_comm_service = new IFrameWidgetCommService();
        this.iframe_ready_notifier = new ReplaySubject();
        this.location = this.scriptData.location;
        const iframe_observable = this.iframe_ready_notifier.asObservable();
        this.widget_config = null;
        this.deviceSize = {
            width: window.innerWidth + "px",
            height: window.innerHeight + "px",
        };
        // Add an event listener for window resize
        //* load the widget config then iframe when the document is ready
        if (document.readyState !== "loading") {
            this.getWidgetConfig();
        }
        else {
            document.addEventListener("DOMContentLoaded", () => {
                this.getWidgetConfig();
            });
        }
        // * Intial trigger Event
        iframe_observable.subscribe((result) => {
            setTimeout(() => {
                const session_id = AppConfig.getUserData("session_id");
                this.iframe_comm_service.postMessage(new CommMessage(OEvents.CHATBOT_START, {
                    botId: this.scriptData.botId,
                    matomoId: this.scriptData.matomoId,
                    getaHost: this.scriptData.getaHost,
                    location: this.scriptData.location,
                    session_id: session_id,
                    widget_config: this.widget_config,
                    chatOpenedStatus: false,
                    deviceSize: this.deviceSize,
                    currentDevice: window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
                        ? "mobile"
                        : "desktop",
                }));
            }, 100);
        });
        // * Chatbot Resize window Event
        this.iframe_comm_service.registerEventListener(IEvents.CHATBOT_OPEN, (event) => {
            const status = event.data?.data?.open ?? false;
            const deviceSize = event.data?.data?.deviceSize ?? null;
            if (!this.widgetUtils)
                return;
            this.widgetUtils.updateIframeDimensions(deviceSize, status);
        });
    }
    getWidgetConfig() {
        // let location: any = window.location.href;
        const config = {
            "geta-host": this.scriptData.getaHost,
        };
        let parentURL = [];
        if (this.location) {
            parentURL = new URL(this.location).searchParams;
        }
        const paramsObject = {
            bot_id: this.scriptData.botId,
        };
        parentURL.forEach((value, key) => {
            paramsObject[key] = value;
        });
        let result = '';
        for (const key in paramsObject) {
            if (paramsObject.hasOwnProperty(key)) {
                if (result !== '') {
                    result += '&';
                }
                result += key + '=' + encodeURIComponent(paramsObject[key]);
            }
        }
        WidgetNetworkService.getWidgetConfig(result, config)
            // WidgetNetworkService.getWidgetConfig(paramsObject.bot_id, config)
            .then((res) => {
            this.widget_config = res?.data;
            //* load the widget iframe when the config is ready
            this.initIFrame(res?.data);
        })
            .catch((err) => {
            console.error("geta widget config error", err);
        })
            .finally(() => { });
    }
    /* actual chatbot iframe */
    initIFrame(res) {
        let url = process.env.REACT_APP_WHATSAPP_WIDGET_BASE_URL || "http://localhost:8083";
        // Create the iframe element
        const iframe = document.createElement("iframe");
        // Store the iframe element in your class property
        this.iframe_element = iframe;
        // Set the iframe element to the comm service
        this.iframe_comm_service.setIFrameElement(this.iframe_element);
        this.widgetUtils = new WidgetStylingUtils(res, iframe);
        iframe.src = url;
        iframe.title = "geta_widget";
        iframe.name = "geta_widget";
        iframe.id = "geta_widget";
        iframe.allowFullscreen = true;
        iframe.style.position = "fixed";
        iframe.style.zIndex = "9999999999999";
        iframe.style.display = "inline";
        iframe.style.overflow = "hidden";
        iframe.style.border = "none";
        iframe.style.height = "1px";
        iframe.style.width = "1px";
        iframe.style.minHeight = "1px";
        iframe.style.minWidth = "1px";
        iframe.style.borderRadius = "2px";
        iframe.style.transition = "all 0.12s ease-in-out";
        this.widgetUtils.getWidgetIntialStyling();
        // Append the iframe to the document body
        document.body.appendChild(iframe);
        this.iframe_element.onload = () => {
            this.iframe_ready_notifier.next(true);
        };
    }
}
