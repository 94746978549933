import { LocalStorage } from "./LocalStorage";
export const ADMIN_KEY = "loggedInPortalAdminData";
export const USER_KEY = "loggedInUserData";
export const SESSION_KEY = "SavedSessionData";
const CONFIG_PARAMS = {
// 'API_BASE_URL': environment.API_BASE_PATH,
};
const localStorage = new LocalStorage();
export class AppConfig {
    static getConfig(param_name) {
        if (this.config.hasOwnProperty(param_name)) {
            return this.config[param_name];
        }
        return false;
    }
    static getCSRFToken() {
        return this.getCookie("csrftoken");
    }
    static getCookie(name) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
        return null;
    }
    static setUserData(key, value) {
        const stringValue = JSON.stringify(value);
        localStorage.set(key, stringValue);
    }
    static deleteUserData(key) {
        localStorage.remove(key);
    }
    static getUserData(key) {
        const stringValue = localStorage.get(key);
        if (stringValue && typeof stringValue == "string") {
            return JSON.parse(stringValue);
        }
        return stringValue;
    }
}
Object.defineProperty(AppConfig, "config", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: CONFIG_PARAMS
});
