import { BREAKPOINTS_DEVICE_SIZE } from "./utils/contants";
export class WidgetStylingUtils {
    constructor(widget_config, iframe_element) {
        Object.defineProperty(this, "iframe_element", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "widget_config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentDevice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "desktop"
        });
        Object.defineProperty(this, "currentPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "Right"
        });
        this.iframe_element = iframe_element;
        this.widget_config = widget_config;
        this.currentDevice =
            window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
                ? "mobile"
                : "desktop";
        this.currentPosition =
            this.currentDevice === "mobile"
                ? widget_config?.chatbot_configuration?.mobile_display_position
                : widget_config?.chatbot_configuration?.desktop_display_position;
    }
    getWidgetIntialStyling() {
        this.getDisplayStatus();
        this.getIframeBottomMargin();
        this.getIframeSidePosition();
    }
    getDisplayStatus() {
        const device = window.innerWidth < BREAKPOINTS_DEVICE_SIZE.tablet &&
            window.innerWidth >= BREAKPOINTS_DEVICE_SIZE.mobileL
            ? "tablet"
            : window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
                ? "mobile"
                : "desktop";
        let visibility = this.widget_config.chatbot_configuration.enabled_devices;
        if (visibility?.[device] === false) {
            this.iframe_element.style.display = "none";
        }
        let urls = this.widget_config.chatbot_configuration?.urls?.blacklisted_urls ?? [];
        let currentUrl = window.location.href;
        urls?.length > 0 && urls?.forEach((url) => {
            if (currentUrl?.includes(url)) {
                console.warn('URL is blacklisted');
                this.iframe_element.style.display = "none";
            }
        });
    }
    updateIframeDimensions(deviceSize, status = false, device = this.currentDevice) {
        this.iframe_element.style.width = deviceSize?.width;
        this.iframe_element.style.height = deviceSize?.height;
    }
    centerIframePosition() {
        this.iframe_element.style.left = 0;
        this.iframe_element.style.right = 0;
        this.iframe_element.style.margin = "0 auto"; /* Center horizontally */
    }
    // margin Bottom
    getIframeBottomMargin(curDevice = this.currentDevice) {
        if (curDevice === "mobile") {
            this.iframe_element.style.bottom =
                `${this.widget_config?.chatbot_configuration?.mobile_bottom_margin}px` ??
                    "10px";
        }
        else {
            this.iframe_element.style.bottom =
                `${this.widget_config?.chatbot_configuration?.desktop_bottom_margin}px` ??
                    "10px";
        }
    }
    getIframeSidePosition(pos = this.currentPosition) {
        if (pos === "Left") {
            this.getIframeLeftMargin();
        }
        if (pos === "Right") {
            this.getIframeRightMargin();
        }
    }
    // margin left
    getIframeLeftMargin(curDevice = this.currentDevice) {
        if (curDevice === "mobile") {
            this.iframe_element.style.right = "auto";
            this.iframe_element.style.left =
                `${this.widget_config?.chatbot_configuration?.mobile_side_margin}px` ??
                    "10px";
        }
        else {
            this.iframe_element.style.right = "auto";
            this.iframe_element.style.left =
                `${this.widget_config?.chatbot_configuration?.desktop_side_margin}px` ??
                    "10px";
        }
    }
    // margin right
    getIframeRightMargin(curDevice = this.currentDevice) {
        if (curDevice === "mobile") {
            this.iframe_element.style.left = "auto";
            this.iframe_element.style.right =
                `${this.widget_config?.chatbot_configuration?.mobile_side_margin}px` ??
                    "10px";
        }
        else {
            this.iframe_element.style.left = "auto";
            this.iframe_element.style.right =
                `${this.widget_config?.chatbot_configuration?.desktop_side_margin}px` ??
                    "10px";
        }
    }
}
