import { INCOMING_EVENTS, OUTGOING_EVENTS, } from "./utils/models";
export class IFrameWidgetCommService {
    constructor() {
        Object.defineProperty(this, "iframe_element", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ievent_fn_map", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "messageListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.iframe_element = null;
        this.ievent_fn_map = new Map();
        this.messageListener = (event) => {
            this.handleMessage(event);
        };
        this.initMessageListeners();
    }
    initMessageListeners() {
        window.addEventListener("message", this.messageListener, false);
    }
    handleMessage(event) {
        if (event.data && event.data.hasOwnProperty("event_type")) {
            const event_name = event.data.event_type;
            if (INCOMING_EVENTS.has(event_name)) {
                const callback = this.ievent_fn_map.get(event_name);
                callback && callback(event);
            }
        }
    }
    setIFrameElement(iframe) {
        this.iframe_element = iframe;
    }
    registerEventListener(event_name, event_callback) {
        if (INCOMING_EVENTS.has(event_name)) {
            this.ievent_fn_map.set(event_name, event_callback);
        }
        else {
            throw new Error("IEvent Type not available!");
        }
    }
    unregisterEventListener(event_name) {
        this.ievent_fn_map.delete(event_name);
    }
    postMessage(message) {
        if (this.iframe_element) {
            if (OUTGOING_EVENTS.has(message.event_type)) {
                if (this.iframe_element.contentWindow) {
                    this.iframe_element.contentWindow.postMessage(message, "*");
                }
            }
            else {
                throw new Error("OEvent Type not available!");
            }
        }
        else {
            throw new Error("Iframe not available!");
        }
    }
    cleanup() {
        window.removeEventListener("message", this.messageListener);
        this.ievent_fn_map.clear();
        this.iframe_element = null;
    }
}
