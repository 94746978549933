import http from "./http";
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1/";
export class WidgetNetworkService {
    static getWidgetConfig(payload, headers) {
        // const url = `${consoleBaseUrl}${Endpoints.WIDGET_CONFIG}/?bot_id=${payload}`;
        const url = `${consoleBaseUrl}${Endpoints.WIDGET_CONFIG}/?${payload}`;
        return http.get(url, { headers });
    }
}
//*Channels *//
class Endpoints {
}
Object.defineProperty(Endpoints, "WIDGET_CONFIG", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "widget-config"
});
