export var IEvents;
(function (IEvents) {
    IEvents["CHATBOT_OPEN"] = "chatbot_open";
    IEvents["CHATBOT_SESSION_ID"] = "chatbot_session_id";
    IEvents["CHATBOT_RESIZE"] = "chatbot_resize";
})(IEvents || (IEvents = {}));
export const INCOMING_EVENTS = new Set([
    IEvents.CHATBOT_OPEN,
    IEvents.CHATBOT_SESSION_ID,
    IEvents.CHATBOT_RESIZE,
]);
export var OEvents;
(function (OEvents) {
    OEvents["CHATBOT_START"] = "chatbot_start";
    OEvents["CHATBOT_SAVE_VISITOR_ID"] = "chatbot_save_visitor_id";
    OEvents["CHATBOT_RESIZE"] = "chatbot_resize";
})(OEvents || (OEvents = {}));
export const OUTGOING_EVENTS = new Set([
    OEvents.CHATBOT_START,
    OEvents.CHATBOT_SAVE_VISITOR_ID,
    OEvents.CHATBOT_RESIZE,
]);
export class CommMessage {
    constructor(event_type, data) {
        Object.defineProperty(this, "event_type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.event_type = event_type;
        this.data = data;
    }
}
