import axios from "axios";
axios.defaults.headers.common.accept = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const addLoginHeaders = (config) => {
    // console.log("config enter", config);
    // Remove the "Content-Type" header if it exists
    delete config.headers["Content-Type"];
    // console.log("config exit", config);
    return config;
};
axios.interceptors.request.use(function (config) {
    config = addLoginHeaders(config);
    return config;
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    //something with error
    return Promise.reject(error);
});
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    // headers,
};
