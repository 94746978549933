import SecureLS from "secure-ls";
const encodingType = "des";
let storage = null;
class LocalStorage {
    constructor() {
        Object.defineProperty(this, "set", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (key, value) {
                storage.set(key, value);
            }
        });
        Object.defineProperty(this, "get", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (key) {
                return storage.get(key) || null;
            }
        });
        Object.defineProperty(this, "getAllKeys", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                storage.getAllKeys();
            }
        });
        Object.defineProperty(this, "removeAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                storage.removeAll();
            }
        });
        Object.defineProperty(this, "remove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (key) {
                storage.remove(key);
            }
        });
        Object.defineProperty(this, "getOrSet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (key, value) {
                let result = storage.get(key);
                if (!result) {
                    storage.set(key, value);
                    result = value;
                }
                return result;
            }
        });
    }
}
class MockLocalStorage {
    constructor() {
        Object.defineProperty(this, "storageMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.storageMap = new Map();
    }
    set(key, value) {
        this.storageMap.set(key, value);
    }
    ;
    get(key) {
        return this.storageMap.get(key) || null;
    }
    ;
    getAllKeys() {
        return Array.from(this.storageMap.keys());
    }
    ;
    removeAll() {
        this.storageMap.clear();
    }
    ;
    remove(key) {
        this.storageMap.delete(key);
    }
    ;
    getOrSet(key, value) {
        let result = this.storageMap.get(key);
        if (!result) {
            this.storageMap.set(key, value);
            result = value;
        }
        return result;
    }
    ;
}
try {
    const ls = window.localStorage;
    storage = new SecureLS({
        encodingType: encodingType,
        isCompression: false,
        encryptionSecret: 'des',
    });
}
catch (err) {
    storage = new MockLocalStorage();
}
export { LocalStorage };
