export const desktopWidgetDimesionsClosedState = {
    height: "200px",
    width: "200px",
};
export const desktopWidgetDimesionsOpenedState = {
    height: "360px",
    width: "360px",
};
export const mobileWidgetDimesionsClosedState = {
    height: "200px",
    width: "200px",
};
export const mobileWidgetDimesionsOpenedState = {
    height: "560px",
    width: "90vw",
};
export const getWidgetDimensions = (device = "desktop", openedStatus = false) => {
    let temp = desktopWidgetDimesionsClosedState;
    if (device === "mobile") {
        if (openedStatus) {
            temp = mobileWidgetDimesionsOpenedState;
            return temp;
        }
        else {
            temp = mobileWidgetDimesionsClosedState;
            return temp;
        }
    }
    else {
        if (openedStatus) {
            temp = desktopWidgetDimesionsOpenedState;
            return temp;
        }
        else {
            temp = desktopWidgetDimesionsClosedState;
            return temp;
        }
    }
};
export var BREAKPOINTS_DEVICE_SIZE;
(function (BREAKPOINTS_DEVICE_SIZE) {
    BREAKPOINTS_DEVICE_SIZE[BREAKPOINTS_DEVICE_SIZE["mobileL"] = 460] = "mobileL";
    BREAKPOINTS_DEVICE_SIZE[BREAKPOINTS_DEVICE_SIZE["tablet"] = 768] = "tablet";
    BREAKPOINTS_DEVICE_SIZE[BREAKPOINTS_DEVICE_SIZE["laptop"] = 1024] = "laptop";
})(BREAKPOINTS_DEVICE_SIZE || (BREAKPOINTS_DEVICE_SIZE = {}));
