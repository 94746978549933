import { GetAChatBotFrame } from "./chatbot-iframe";
import * as Sentry from "@sentry/react";
class GetA {
    constructor(data) {
        //define other tracking variables here
        Object.defineProperty(this, "chatbot_frame", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "configData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (window.location.origin === "https://bot-wframe.geta.ai/") {
            Sentry.init({
                dsn: "https://3a8c96d3756610ab73409b15fcc4d735@o4507446172188672.ingest.us.sentry.io/4507462883475456",
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration(),
                ],
                environment: process.env.REACT_APP_SENTRY_ENV,
                // Performance Monitoring
                tracesSampleRate: 1.0,
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // tracePropagationTargets: ["localhost"],
                tracePropagationTargets: [process.env.REACT_APP_SENTRY_DOMAIN],
                // Session Replay
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }
        //define other service class here
        this.configData = data;
        this.chatbot_frame = new GetAChatBotFrame(data);
    }
}
(function (window) {
    window.on = (g, m, b, location) => {
        let data = {
            getaHost: g,
            matomoId: m,
            botId: b,
            location: window.location.href,
        };
        return new GetA(data);
    };
})(window);
